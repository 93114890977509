<template>
  <SystemPageWrapper :loading="isLoading">
    <div
      v-if="cantActivate"
      class="system-page__icon"
    >
      <icon
        data="@icons/style_error_double.svg"
        class="size-56 fill-off"
      />
    </div>
    <h1 class="system-page__title heading-1-md grey-100">
      {{ activationTitle }}
    </h1>
    <SlButton
      v-if="isSingleCompany"
      :disabled="isActivationLoading"
      @click="selectTenant(availableTenants[0])"
    >
      {{ $t('Web.Modals.BtnConfirm') }}
    </SlButton>
    <div
      v-else-if="!cantActivate"
      class="tenant-cards"
      :class="{
        'tenant-cards--two-columns': isTwoColumns
      }"
    >
      <div
        v-for="tenant in availableTenants"
        :key="tenant.tenantId"
        class="tenant-card"
        @click="selectTenant(tenant)"
      >
        {{ tenant.name }}
      </div>
    </div>
    <div class="system-page__link">
      <SlLink
        color="primary"
        @click.prevent="login"
      >
        {{ $t('Web.GetStarted.SwitchAcc') }}
      </SlLink>
    </div>
  </SystemPageWrapper>
</template>

<script>
import { ContextHolder } from '@frontegg/vue';
import initializationApi from '@/api/initialization.js';
import { initialization } from '@/mixins/initialization';

export default {
  name: 'ActivateStreamline',
  mixins: [initialization],
  data() {
    return {
      isActivationLoading: false
    };
  },
  computed: {
    availableTenants() {
      const userTenants = this.authState?.tenantsState?.tenants || [];
      const userRoleByTenantId = this.authState?.user?.tenants?.reduce((acc, tenant) => {
        acc[tenant.tenantId] = tenant.roles;

        return acc;
      }, {});

      return userTenants.filter(tenant => {
        return userRoleByTenantId[tenant.tenantId]
          .some(role => role.key === this.$data.$sl_fe_adminRoleKey);
      });
    },
    isSingleCompany() {
      return this.availableTenants.length === 1;
    },
    cantActivate() {
      return this.availableTenants.length === 0;
    },
    activationTitle() {
      if (this.cantActivate) {
        return this.$t('Web.NoAccess.AdminActivation');
      }

      if (this.isSingleCompany) {
        return this.$t('Web.Project.Activation.Single', {
          1: this.availableTenants[0].name
        });
      }

      return this.$t('Web.Project.Activation.Multiple');
    },
    isTwoColumns() {
      return this.availableTenants?.length > 5;
    },
    isLoading() {
      return this.authState?.tenantsState?.loading || this.isActivationLoading;
    }
  },
  watch: {
    'authState.user.tenantId'(newId, oldId) {
      if (newId && oldId) {
        this.activateServer();
      }
    }
  },
  methods: {
    login() {
      const baseUrl = ContextHolder.getContext().baseUrl;

      window.location.replace(`${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`);
    },
    async selectTenant(tenant) {
      try {
        if (tenant.tenantId !== this.authState.user.tenantId) {
          await this.$sl_switchTenant(tenant.tenantId);
        }

        await this.activateServer();
      } catch (e) {
        this.$notify({
          type: 'error',
          title: e,
          duration: 7000
        });
      }
    },
    async activateServer() {
      try {
        this.isActivationLoading = true;

        await initializationApi.setTenantInfo();

        await this.postActivationInit();
      } catch (e) {
        // todo: ask teammates
        this.$notify({
          type: 'error',
          title: e?.message ?? e,
          duration: 7000
        });
      } finally {
        this.isActivationLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/shared/page/system-page/get-started";
@import "@/style/components/shared/page/system-page/activation";
</style>
